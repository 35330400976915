import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject, merge, empty, of } from 'rxjs';
import { LogradouroCdl } from '../model/LogradouroCdl';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CdlService {

    private cdlurl = 'https://api.procempa.com.br/apiman-gateway/urbanismo/cdl-servico/1.0/rest/query/endereco';
    private apiKey = '9bbfef8e-79d8-4210-938e-bc54dfeec014';

	habilitaDadosHistoricos = false;
	reset = false;
	maxResults = 5;

	getLogradouros(nome?: string, numero?: string) {
		if (!nome) {
			return of<LogradouroCdl[]>([]);
		}

		let url = `${this.cdlurl}?apikey=${this.apiKey}`;
		url += nome ? `&q=${nome}` : '';
		url += numero ? `&numero=${numero}` : ``;
		url += this.habilitaDadosHistoricos ? `&dataInicioHistorico=19000101\'` : ``;
		url += this.reset ? `&reset=${this.reset}` : ``;
		return this.http.get<LogradouroCdl[]>(url).pipe(catchError(this.errorHandler));
	}

	getLogradouro(codigoLogradouro: string, numero: string): Observable<LogradouroCdl> {
		const url = `${this.cdlurl}/${codigoLogradouro}?apikey=${this.apiKey}&numero=${numero}`;
		return this.http.get<LogradouroCdl>(url).pipe(catchError(this.errorHandler));
	};

	private errorHandler(httpError: HttpErrorResponse) {
		let errMsg: string;
		if (httpError.error instanceof Error) {
			errMsg = `${httpError.error.message} - ${httpError.message}`;
		}
		else {
			errMsg = `${httpError.status} - ${httpError.message}`;
		}
		console.error(errMsg);
		return throwError(`${errMsg}`);
	}

	constructor(private http: HttpClient) {
	}
}
