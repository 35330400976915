import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { CdlLogradouroDirective, ProcempaCdl } from '../directives/cdl-directive';

@Component({
	selector: 'cdl-list-logradouro',
	template: `<div class="cdl-feedback" *ngIf="isSearching || notFound">
	<span class="glyphicon" [ngClass]="feedback" aria-hidden="true"></span>
	<span class="sr-only">{{tipo}}</span> {{acao}}
</div>
<!-- aqui aparecem os resultados da pesquisa, clicÃ¡veis para selecionar um objeto -->
<div class="dropdown" [ngClass]="abrir">
	<ul class="dropdown-menu" [ngClass]="abrir">
		<li class="dropdown-item" *ngFor="let l of listaLogradouros; trackBy: trackLogradouro">
			<a href="javascript:void(0)" (click)="selecionaEndereco(l)">{{l.enderecoFormatado}}</a>
		</li>
	</ul>
</div>
`,
	styles: [``]
})
export class CdlListLogradouroComponent implements OnInit {

	@Input()
	listaLogradouros: any[];
	@Input()
	isChoosing = false;
	@Input()
	isSearching = false;
	@Input()
	notFound = false;
	seleciona = new EventEmitter();

	get tipo() {
		return this.isChoosing ? 'Escolhendo' :
			this.isSearching ? 'Procurando' :
				this.notFound ? 'Sem resultados' :
					'';
	}

	get acao() {
		return this.isChoosing ? '' :
			this.isSearching ? 'Procurando...' :
				this.notFound ? 'Sem resultados' :
					'';
	}

	get feedback() {
		return {
			'glyphicon-refresh': this.isSearching,
			'glyphicon-ban-circle': this.notFound
		}
	}

	get abrir() {
		return { 'open show': this.isChoosing };
	}

	ngOnInit(): void {
	}

	selecionaEndereco(logradouro) {
		this.isChoosing = false;
		this.seleciona.emit(logradouro);
	}

	trackLogradouro(index, item){
		return item.codigoLogradouro
	}

	constructor() {
	}

}
