import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LogradouroCdl } from '../model/LogradouroCdl';

@Component({
	selector: 'procempa-cdl',
	template: `<div procempaCdl [codigo]="logradouroCdl?.codigoLogradouro" [numero]="logradouroCdl?.numero" [localizar]="localizar" (inicial)="logradouroCdl = $event">

    <div class="form-group">
        <label for="cdl-logradouro" class="control-label">Logradouro</label>
        <input cdlLogradouro [(ngModel)]="logradouro" (seleciona)="selecionaEndereco($event)" [required]="isRequired" autocomplete="off" type="text"
         name="cdl-logradouro" id="cdl-logradouro" class="form-control">
    </div>

    <div *ngIf="isCompleto">
        <div class="form-group col-xs-12 col-sm-3 right">
            <label for="inputNumeroImovel" class="control-label">NÃºmero</label>
            <input cdlNumero [(ngModel)]="numero" autocomplete="off" class="form-control" name="inputNumeroImovel" id="inputNumeroImovel"
             placeholder="informe o nÃºmero do endereÃ§o">
        </div>

        <div class="form-group col-xs-12 col-sm-9">
            <label for="inputCepImovel" class="control-label">CEP</label>
            <input [ngModel]="logradouroCdl?.cep" type="text" class="form-control" name="inputCepImovel" id="inputCepImovel"
             disabled>
        </div>

        <div class="form-group col-xs-12">
            <label for="inputBairroImovel" class="control-label">Bairro</label>
            <input type="text" class="form-control" name="inputBairroImovel" id="inputBairroImovel" disabled [ngModel]="logradouroCdl?.nomeBairro">
        </div>
    </div>

</div>
`,
	styles: [`.right{margin-right:20px;margin-left:0}@media (min-width:1280px) and (max-width:1440px){.right{width:21.7%}}@media (min-width:1440px) and (max-width:1680px){.right{width:22.1%}}@media (min-width:1680px) and (max-width:1920px){.right{width:22.6%}}@media (min-width:1920px){.right{width:22.9%}}`]
})

export class CdlPesquisaComponent implements OnInit {

	// logradouro selecionado
	@Output() onSelecionaEndereco = new EventEmitter<LogradouroCdl>();
	// logradouro previo
	@Input() logradouroCdl: LogradouroCdl;
	// mostra input logradouro, cep e nÃºmero
	@Input() isCompleto = true;
	// torna alguns campos requireds
    @Input() isRequired = false;
    // indica se deve fazer o geocode do endereÃ§o
    @Input() localizar = false;
	codigo;
	numero;
	logradouro;

	constructor() { }

	ngOnInit() {
	}

	selecionaEndereco(logradouroCdl?: LogradouroCdl) {
		this.logradouroCdl = logradouroCdl;
		this.onSelecionaEndereco.emit(logradouroCdl);
	}

}
