import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CdlService } from './service/cdl-service';
import { GeoService } from './service/geo-service';
import { CdlListLogradouroComponent } from './cdl-list-logradouro/cdl-list-logradouro.component';
import { ProcempaCdl, CdlLogradouroDirective, CdlNumeroDirective, CdlCodigoDirective } from './directives/cdl-directive';
import { CdlPesquisaComponent } from './cdl-pesquisa/cdl-pesquisa.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
    declarations: [
        ProcempaCdl,
        CdlLogradouroDirective,
        CdlNumeroDirective,
        CdlCodigoDirective,
        CdlPesquisaComponent,
        CdlListLogradouroComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule
    ],
    exports: [
        ProcempaCdl,
        CdlLogradouroDirective,
        CdlNumeroDirective,
        CdlCodigoDirective,
        CdlPesquisaComponent
    ],
    entryComponents: [CdlListLogradouroComponent]
})
export class CdlLibModule {

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CdlLibModule,
            providers: [CdlService, GeoService]
        };
    }
}
