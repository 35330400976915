import { Directive, Input, Output, ElementRef, EventEmitter, HostListener, OnInit, Host, AfterContentChecked, DoCheck, OnDestroy, ViewContainerRef, ComponentFactoryResolver, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Observable, Observer, Subscription, empty, Subject, merge, throwError } from 'rxjs';

import { CdlService } from '../service/cdl-service';
import { LogradouroCdl } from '../model/LogradouroCdl';
import { debounceTime, mergeMap, switchMap, tap, distinctUntilChanged, map, catchError } from 'rxjs/operators';
import { CdlListLogradouroComponent } from '../cdl-list-logradouro/cdl-list-logradouro.component';
import { NgModel, NgControl } from '@angular/forms';
import { GeoService } from '../service/geo-service';
import { Localizacao } from '../model/Localizacao';

@Directive({
    selector: '[procempaCdl]'
})
export class ProcempaCdl {

    @Input()
    maxResults = 5;
    @Input()
    habilitaDadosHistoricos = false;
    @Input('codigo')
    codigoInicial: string
    @Input('numero')
    numeroInicial: string;
    @Input()
    localizar = false;
    @Output()
    inicial = new EventEmitter();

    logradouro: string;
    localizacao: Localizacao;

    // streams
    private subjectLogradouro: Subject<string>;
    private subjectNumero: Subject<string>;
    private evento = new EventEmitter<any>();

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.codigoInicial && this.numeroInicial) {
            this.cdlService.getLogradouro(this.codigoInicial, this.numeroInicial)
                .subscribe(value => {
                    this.logradouro = value.nomeLogradouro;
                    if (this.localizacao) {
                        value.localizacao = this.localizacao;
                    }
                    this.emitirEventoChange(value);
                });
        }
    }

    private emitirEventoChange(dados) {
        this.evento.emit({ tipo: 'change', dados });
        this.inicial.emit(dados);
    }

    private emitirEventoEscolher(dados) {
        this.evento.emit({ tipo: 'escolher', dados });
    }

    set logradouroQuery(value: string) {
        this.logradouro = value;
        this.subjectLogradouro.next(value);
    }

    set numeroQuery(value: string) {
        this.numeroInicial = value;
        this.subjectNumero.next(value);
    }

    get eventos() {
        return this.evento.asObservable();
    }

    selecionaLogradouro(value: LogradouroCdl) {
        const v = value || {};
        this.logradouro = v.nomeLogradouro;

        if (this.localizar && v.codigoLogradouro && v.numero) {
            this.geoService.getLocalizacao(v.codigoLogradouro, v.numero).subscribe(geoRes => {
                value.localizacao = geoRes;
                this.localizacao = geoRes;
                this.emitirEventoEscolher(value);
            });
        }else {
					this.emitirEventoEscolher(value);
				}
    }

    dataSource() {
        const streams = [
            this.subjectNumero,
            this.subjectLogradouro,
        ];

        return merge(...streams).pipe(
            debounceTime(1 * 1000),
						tap(() => this.evento.emit({ tipo: 'procurando' })),
            mergeMap(inputs => this.cdlService.getLogradouros(this.logradouro, this.numeroInicial)),
            map(logradouros => logradouros.slice(0, this.maxResults)),
            catchError((e) => {
                this.restartSubjects();
                return throwError(e);
            }));
    }

    private observarLogradouro() {
        this.subjectLogradouro = new Subject<string>();
    }

    private observarNumero() {
        this.subjectNumero = new Subject<string>();
    }

    private restartSubjects() {
        this.observarLogradouro();
        this.observarNumero();
    }

    constructor(private cdlService: CdlService, private geoService: GeoService) {
        this.restartSubjects();
    }
}

@Directive({
    selector: '[cdlLogradouro]'
})
export class CdlLogradouroDirective implements OnInit, OnDestroy {

    private _subscriptions: any[];
    private _logradouroComponent: CdlListLogradouroComponent;
    @Output()
    seleciona = new EventEmitter();

    ngOnInit(): void {

        this.criarComponente();

        this.subscriptions = this.procempaCdl.dataSource()
            .subscribe(logradouros => {
                this._logradouroComponent.isSearching = false;
                this._logradouroComponent.isChoosing = logradouros.length > 0;
                this._logradouroComponent.notFound = logradouros.length === 0;
                this._logradouroComponent.listaLogradouros = [...logradouros];
                if (logradouros.length === 0) {
                    this.procempaCdl.selecionaLogradouro(undefined);
                }
                this.changeDetector.markForCheck();
            });

        this.subscriptions = this.listenEvents().subscribe();
    }

    private criarComponente() {
        const c = this.resolver.resolveComponentFactory(CdlListLogradouroComponent);
        this.viewContainerRef.clear();
        const cRef = this.viewContainerRef.createComponent(c);
        this._logradouroComponent = cRef.instance;
    }

    private listenEvents() {

        const cdlList = this._logradouroComponent.seleciona.pipe(map(value => ({ tipo: 'view-select', dados: value })));
        const mutations = [
            cdlList,
            this.procempaCdl.eventos
        ];

        return merge(...mutations).pipe(
            tap(rs => {
                this.changeDetector.markForCheck();
                switch (rs.tipo) {
                    case 'procurando':
                        this._logradouroComponent.isSearching = true;
                        this._logradouroComponent.isChoosing = false;
                        this._logradouroComponent.notFound = false;
                        break;
                    case 'escolher':
                        this.selecionaLogradouro(rs.dados);
                        break;
                    case 'change':
                        this.mudaLogradouro(rs.dados);
                        break;
										case 'view-select':
                        this.procempaCdl.selecionaLogradouro(rs.dados);
                        break;
                    default:
                        break;
                }
            }));
    }

    @HostListener('input', ['$event.target'])
    onInput($event: any) {
        const l = $event.value;
        this.procempaCdl.logradouroQuery = l;
        return false;
    }

    selecionaLogradouro(logradouro: LogradouroCdl) {
        const l = logradouro || {};
        this.model.control.setValue(l.nomeLogradouro);
        this.model.viewToModelUpdate(l.nomeLogradouro);
        this.changeDetector.markForCheck();
        this.seleciona.emit(logradouro);
    }

    private mudaLogradouro(logradouro: LogradouroCdl) {
        const l = logradouro || {};
        this.model.control.setValue(l.nomeLogradouro);
        this.model.viewToModelUpdate(l.nomeLogradouro);
        this.changeDetector.markForCheck();
    }

    private set subscriptions(value) {
        this._subscriptions = this._subscriptions || [];
        if (!this._subscriptions.includes(value)) {
            this._subscriptions.push(value);
        }
    }

    ngOnDestroy() {
        if (this._subscriptions) {
            this._subscriptions.forEach(subs => subs.unsubscribe());
            this._subscriptions = undefined;
        }
    }

    constructor(
        private viewContainerRef: ViewContainerRef,
        private element: ElementRef,
        private resolver: ComponentFactoryResolver,
        private model: NgModel,
        private changeDetector: ChangeDetectorRef,
        @Host() private procempaCdl: ProcempaCdl
    ) { }
}

@Directive({
    selector: '[cdlNumero]'
})
export class CdlNumeroDirective {

    @HostListener('input', ['$event.target'])
    onKeyUp($event: any) {
        const n = $event.value;
        this.procempaCdl.numeroQuery = n;
        return false;
    }

    constructor(private el: ElementRef, @Host() private procempaCdl: ProcempaCdl, private model: NgModel) {
        procempaCdl.eventos.subscribe(rs => {
            switch (rs.tipo) {
                case 'change':
								case 'escolher':
									const n: LogradouroCdl = rs.dados || {};
                  this.model.control.setValue(n.numero);
                  this.model.viewToModelUpdate(n.numero);
                  break;
                default:
                    break;
            }
        });
    }
}

@Directive({
    selector: '[cdlCodigo]'
})
export class CdlCodigoDirective {

    constructor(private el: ElementRef, @Host() private procempaCdl: ProcempaCdl, private model: NgModel) {
        procempaCdl.eventos.subscribe(rs => {
            switch (rs.tipo) {
                case 'change':
								case 'escolher':
									const c: LogradouroCdl = rs.dados || {};
                  this.model.control.setValue(c.codigoLogradouro);
                  this.model.viewToModelUpdate(c.codigoLogradouro);
                  break;
                default:
                    break;
            }
        });
    }
}
