import { Localizacao } from "./Localizacao";

export class LogradouroCdl {
    codigoLogradouro?: number;
    numero?: number;
    nomeLogradouro?: string;
    nomeHistorico?: string;
    dataHistorico?: string;
    codigoLogradouroInicio?: number;
    nomeLogradouroInicio?: string;
    codigoLogradouroFim?: number;
    nomeLogradouroFim?: string;
    situacao?: string;
    codigoBairro?: number;
    nomeBairro?: string;
    lado?: string;
    nomeBairroHistorico?: string;
    cep?: string;
    enderecoFormatadoCurto?: string;
    enderecoFormatado?: string;
    localizacao?: Localizacao
}
