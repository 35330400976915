import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject, merge, empty, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Localizacao } from '../model/Localizacao';

@Injectable()
export class GeoService {

    private geocodeServiceUrl = 'https://api.procempa.com.br/apiman-gateway/tser/arcgis-producao/1.1/GEOCODE/TM_POA_COD_NOME_COMPOSITE/GeocodeServer/findAddressCandidates?outSR=%7B%22wkid%22%3A+4326%7D&f=json&apikey=9bbfef8e-79d8-4210-938e-bc54dfeec014';

    getLocalizacao(codigoLogradouro: number, numero: number): Observable<Localizacao> {
        const url = `${this.geocodeServiceUrl}&Street=${codigoLogradouro}&Number=${numero}`;
        return this.http.get<any>(url).pipe(
            map(res => {
                try {
                    let localizacao = res.candidates[0].location;
                    return new Localizacao(localizacao.y, localizacao.x);
                } catch (ex) {
                    console.error(ex);
                }
                return res;
            }),
            catchError(this.errorHandler)
        );
    };

    private errorHandler(httpError: HttpErrorResponse) {
        let errMsg: string;
        if (httpError.error instanceof Error) {
            errMsg = `${httpError.error.message} - ${httpError.message}`;
        }
        else {
            errMsg = `${httpError.status} - ${httpError.message}`;
        }
        console.error(errMsg);
        return throwError(`${errMsg}`);
    }

    constructor(private http: HttpClient) {
    }
}
